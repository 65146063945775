import React from "react";
import ReactDOM from 'react-dom';
import $ from 'jquery';

class Files1 extends React.Component{
      constructor(props) {
      super(props)
      // console.log(this.props)
      this.handleClick = this.handleClick.bind(this)
    }
    
    handleClick(event){
      var indx = event.target.getAttribute('indx')
      let filesArr = this.props.filesArr
      var doc_id = this.props.files[indx]['_id']
      var key_to_update = this.props.key_to_update
      let selectedObjIndex = -1

      if (filesArr) {
        selectedObjIndex = filesArr.findIndex(f => f[key_to_update].some(obj => JSON.stringify(obj) == JSON.stringify(this.props.files[indx])));
      }

      let cur_id
      let newArr
      if (selectedObjIndex != -1) {
          cur_id = filesArr[selectedObjIndex]['_id']['$oid'];
          newArr = filesArr[selectedObjIndex][key_to_update].filter(obj => JSON.stringify(obj) != JSON.stringify(this.props.files[indx]));
      }
      let oid = this.props.files[indx]['oid']
      const updateData = this.props.updateData
      let text = "Are you sure to delete this file?";
      var props = this.props
      if (confirm(text) == true){
        $('#loading').show();
        var file_req_url = "/api/v1/delete_file?filename=" + this.props.files[indx]['name'];
        $.get(file_req_url, function(data, status){})
          .fail(function(jqXHR, textStatus, errorThrown) {
            if (updateData && updateData === true && cur_id) {
              let post_obj = {[key_to_update]: newArr};
              
              let update_obj = { obj: post_obj, id: cur_id }
            
              if(props.user_email){
                  update_obj['user'] = props.user_email
              }
              
              if(props.user_key){
                  update_obj['key'] = props.user_key
              }
          
              $.ajax({
                type: "POST",
                url: '/api/v1/update_data',
                data: JSON.stringify(update_obj),
                dataType: "json",
                contentType: "application/json; charset=utf-8",
              })
                .done(function (data) {
                  window.location.reload()
                  $('#loading').hide();
                })
                .fail(function (jqXHR, textStatus, errorThrown) {
                  $('#loading').hide();
                  console.log(errorThrown);
                  alert('Error deleting file!')
                });
            }else{
              $('#loading').hide();
              console.log(errorThrown);
              alert('Error deleting file!')
            }
          })
          .done(function(data, textStatus, jqXHR) {
            if (updateData && updateData === true && cur_id) {
              let post_obj = {[key_to_update]: newArr};
              
              let update_obj = { obj: post_obj, id: cur_id }
            
              if(props.user_email){
                  update_obj['user'] = props.user_email
              }
              
              if(props.user_key){
                  update_obj['key'] = props.user_key
              }
          
              $.ajax({
                type: "POST",
                url: '/api/v1/update_data',
                data: JSON.stringify(update_obj),
                dataType: "json",
                contentType: "application/json; charset=utf-8",
              })
                .done(function (data) {
                  window.location.reload()
                  $('#loading').hide();
                })
                .fail(function (jqXHR, textStatus, errorThrown) {
                  $('#loading').hide();
                  console.log(errorThrown);
                  alert('Error deleting file!')
                });
            } else {
              if (doc_id) {
                
                let delete_obj = { id: doc_id }
            
                if(props.user_email){
                    delete_obj['user'] = props.user_email
                }
                
                if(props.user_key){
                    delete_obj['key'] = props.user_key
                }
                
                $.ajax({
                  type: "POST",
                  url: '/api/v1/delete_data',
                  data: JSON.stringify(delete_obj),
                  dataType: "json", 
                  contentType: "application/json; charset=utf-8",
                })
                .done(function(data) {
                  console.log(data);
                  record_user_action("File deleted", props['by'], props['for'], "", "", props['oid']);
                  alert('File deleted successfully');
                  window.location.reload();
                })
                .fail(function(jqXHR, textStatus, errorThrown) {
                  $('#loading').hide();
                  alert('Error deleting file: ' + errorThrown);
                });
              } else {
                window.location.reload()
                $('#loading').hide();
              }
            }
          })
      }else{
        $('#loading').hide();
        alert('Cancelled');
      }
    }

    render(){
      var fileNum = this.props.files.length
      var items = []
      for(var i=0; i<fileNum; i++){

        var generation = this.props.files[i]['generation'].toString()
        var fileName = this.props.files[i]['name'].slice(21)
        var deleteBtn = !!this.props.allowDeletion ? 
                        <button className="btn btn-danger btn-sm" onClick={this.handleClick} indx={i} >
                          DELETE
                        </button>
                        : ""
        var imageBlockClass = !!this.props.imageBlockClass ? this.props.imageBlockClass : "col-md-2 mb-3" 
        //if(fileName.in && !!this.props.thumbnails[i]){
        if(/.png|.jpg|.jpeg/.test(fileName)){
          items.push(<div className={imageBlockClass} key={i}>
                      <div align="center">
                        <div  className='cursor-pointer' onClick={(e)=>{
                          const modalElement = document.getElementById('image-modal');
                          $('#previewImage').attr('src', e.currentTarget.children[0].getAttribute('src'));
                          $('#downloadLink').attr('href', e.currentTarget.children[0].getAttribute('src'));
                          const modal = new bootstrap.Modal(modalElement);
                          modal.show();
                        }}>
                          <img className="img-thumbnail img-fluid shadow-sm" src={this.props.files[i]['media_url']} alt="" width="250" /><br/>
                          <p className="fs-0">
                            { fileName }
                          </p>
                        </div>
                        {deleteBtn}
                      </div>  
                    </div>)
        }else{
          items.push(<div className={imageBlockClass} key={i}>
                      <div className="p-1" align="center">
                        <a href={this.props.files[i]['media_url']} target="_blank">
                          <span className="fas fa-file-alt fs-5"></span><br/>
                          <p className="p-1 fs-0">{ fileName }</p>
                        </a>
                        {deleteBtn}
                      </div>  
                    </div>)
        }
      }
      return  <div className="row">
                {items}
                <div className="modal fade" id="image-modal" tabIndex="-1" role="dialog" aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered" role="document" style={{maxWidth: "1000px"}}>
                    <div className="modal-content position-relative">
                      <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                        <button className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div className="modal-body p-0">
                        <div className="rounded-top-lg py-3 ps-4 pe-6 bg-light d-flex align-items-center justify-content-between gap-2">
                          <h4 className="mb-1" id="modalExampleDemoLabel">Image Preview</h4>
                          <a href='#' id='downloadLink' target='_blank'><button className='btn btn-primary btn-sm'>Download <span className='ms-2 fas fa-arrow-down'></span></button></a>
                        </div>
                        <div className="p-4">
                          <img src='' alt='previewImage' id='previewImage' style={{width: "100%"}}/>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button className="btn btn-secondary" type="button" data-bs-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
    }
}

export default Files1