import React, { useState,useEffect } from "react";
import { DragDropContext, Draggable, Droppable  } from "react-beautiful-dnd";
// import uuid from "uuid/v4";
import { v4 as uuid } from 'uuid';



function Kanban(props) {
    
    const showArchiveBtn = props.showArchiveBtn;
    const stgArr = props.stages;
    const bgArr = props.bgColor?props.bgColor:[];
    const lead_details_path = props.lead_details_path;
    const [originalObj1,setOriginalObj1] = useState(props.data);
    const [obj1,setObj1] = useState(originalObj1.filter(task => task.archived != true));
    const sortBy = props.sortBy;
    const cardContent = props.cardContent;

    function toTitleCase(str) {
      return str.toLowerCase().split(' ').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    }
    
    const arr = stgArr.map((stg)=>{
      const filterObj = obj1.filter(ele => {
        return ele[sortBy] === stg;
      });
      return { set: filterObj};
    });
    
    const backendItems = arr.map((item)=>{
      const bItems = item.set.map((setVal)=>{
        return { id: uuid(), content: setVal};
      })
      return bItems;
    });

    var cnt = -1;
    let backendColumns = {};
    const backendColumnsArr = backendItems.map((item)=>{
      cnt++;
      return {[uuid()]: {
        name: stgArr[cnt],
        items: item,
        bgCol : bgArr[cnt]?bgArr[cnt]:''
      }}
    });
    
    for(let i = 0; i < arr.length; i++ ) {
      Object.assign(backendColumns, backendColumnsArr[i]);
    }
    const [columns, setColumns] = useState(backendColumns);

    const onDragEnd = (result, columns, setColumns) => {
      if (!result.destination) return;
      const { source, destination } = result;
    
      if (source.droppableId !== destination.droppableId) {
        const sourceColumn = columns[source.droppableId];
        const destColumn = columns[destination.droppableId];
        const sourceItems = [...sourceColumn.items];
        const destItems = [...destColumn.items];
        const [removed] = sourceItems.splice(source.index, 1);
        destItems.splice(destination.index, 0, removed);
        setColumns({...columns,
          [source.droppableId]: {...sourceColumn,items: sourceItems},
          [destination.droppableId]: {...destColumn,items: destItems}
        });
        
        let update_obj = {obj: {[sortBy]: destColumn.name}, id: removed.content["_id"]["$oid"]}
            
        if(props.user_email){
            update_obj['user'] = props.user_email
        }
        
        if(props.user_key){
            update_obj['key'] = props.user_key
        }
        
        if(props.collection_name){
            update_obj['collection_name'] = props.collection_name
        }
        
        var req_url = '/api/v1/update_data'
        var req_data = JSON.stringify(update_obj);
        $.ajax({
          type: "POST",
          url: req_url,
          data: req_data,
          datatype : "application/json",
          contentType: "application/json; charset=utf-8",
          success: function(data){
          console.log("Success: ",data)
          },
          error: function(err){
            console.log("Error: ",err);
          }
        });
      
      } else {
        const column = columns[source.droppableId];
        const copiedItems = [...column.items];
        const [removed] = copiedItems.splice(source.index, 1);
        copiedItems.splice(destination.index, 0, removed);
        setColumns({
          ...columns,
          [source.droppableId]: {
            ...column,
            items: copiedItems
          }
        });
      }
    };
  const setTaskAsArchived = (oid) =>{
    let text = 'Are you sure you want to archive this task?'
    if(confirm(text)){
      $('#loading').show()
      
      let update_obj = {obj: {archived:true}, id: oid}
            
      if(props.user_email){
          update_obj['user'] = props.user_email
      }
      
      if(props.user_key){
          update_obj['key'] = props.user_key
      }
      
      if(props.collection_name){
        update_obj['collection_name'] = props.collection_name
      }
      
      var req_url = '/api/v1/update_data'
      var req_data = JSON.stringify(update_obj);
      // console.log(req_data)
      $.ajax({
        type: "POST",
        url: req_url,
        data: req_data,
        datatype : "application/json",
        contentType: "application/json; charset=utf-8",
        success: function(data){
          $('#loading').hide()
          console.log("Success: ",data)
          setOriginalObj1(updatedTasks => {
            const updated = updatedTasks.map(task => {
              if (task['_id']['$oid'] === oid) {
                return { ...task, archived: true }; // Update the archived status
              }
              return task;
            });
            return updated;
          });

        },
        error: function(err){
          $('#loading').hide()
          alert('Something went wrong!')
          console.log("Error: ",err);
        }
      });
    }
  }
  useEffect(() => {
    setObj1(originalObj1.filter(task => task.archived !== true));
  }, [originalObj1]);

  useEffect(()=>{
    const arr = stgArr.map((stg)=>{
      const filterObj = obj1.filter(ele => {
        return ele[sortBy] === stg;
      });
      return { set: filterObj};
    });
    
    const backendItems = arr.map((item)=>{
      const bItems = item.set.map((setVal)=>{
        return { id: uuid(), content: setVal};
      })
      return bItems;
    });

    var cnt = -1;
    let backendColumns = {};
    const backendColumnsArr = backendItems.map((item)=>{
      cnt++;
      return {[uuid()]: {
        name: stgArr[cnt],
        items: item,
        bgCol : bgArr[cnt]?bgArr[cnt]:''
      }}
    });
    
    for(let i = 0; i < arr.length; i++ ) {
      Object.assign(backendColumns, backendColumnsArr[i]);
    }
    setColumns(backendColumns)
  },[obj1])
  return (
    <>
    
    <div className="alert alert-success collapse" role="alert" id="form-success">
      <p className="mb-0 flex-1">Update saved successfully.</p>
    </div>
    <div className="alert alert-danger collapse" role="alert" id="form-danger">
      <p className="mb-0 flex-1">Something went wrong !</p>
    </div>
    
    <div className="card h-100 mb-4">
    <div className="card-body">
    <div className="board mr-0 ml-0 scrollbar" style={{     
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "start",
        padding: "24px 2px",
        overflow: "auto",
        height: "100%" }}>
      <DragDropContext
        onDragEnd={result => onDragEnd(result, columns, setColumns)}
      >
       
        {Object.entries(columns).map(([columnId, column], index) => {
          // console.log(column)
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              key={columnId}
            >
              
              <div style={{ margin: 8, width: "18rem" }} className={`card rounded ${column.bgCol && column.bgCol.length > 0 ? column.bgCol : 'bg-soft-warning'}`}>
                <div className={`kanban-column-header ${column.bgCol && column.bgCol.length > 0 ? column.bgCol : 'bg-soft-warning'}`}>
                  <h2 className="fs-0 mb-0">{toTitleCase(column.name)}</h2>
                </div>
                <Droppable droppableId={columnId} key={columnId}>
                  {(provided, snapshot) => {
                    return (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                          padding: 4,
                          minHeight: 500
                        }}
                      >
                        {column.items.map((item, index) => {
                          // console.log("item: ",item);
                          return (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(provided, snapshot) => {
                                return (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      userSelect: "none",
                                      margin: "1rem 1.4rem",
                                      minHeight: "50px",
                                      ...provided.draggableProps.style
                                    }}
                                    className = "card kanban-item-card"
                                  >
                                    {
                                        <div className='card-body fw-medium font-sans-serif' style={{position:'relative'}}>
                                        <div className='d-flex align-items-start justify-content-between'>
                                          {"heading" in cardContent &&
                                          (<h5 className='mb-1' style={{width:'80%'}}>
                                            { lead_details_path.trim().length > 0 ?(
                                            <a
                                              href={lead_details_path.toLowerCase()=='refresh'? window.location.href :`${lead_details_path}?oid=${item.content['_id']['$oid']}`}
                                              target={lead_details_path.toLowerCase()=='refresh'?'_self':'_blank'}
                                            >
                                              {cardContent["headingPrefix"] ? cardContent["headingPrefix"] + ' ' : ''} 
                                              {item.content[cardContent["heading"]]} 
                                              {cardContent["headingSuffix"] ?  ' ' + cardContent["headingSuffix"]: ''}
                                            </a>):(
                                            <>
                                              {cardContent["headingPrefix"] ? cardContent["headingPrefix"] + ' ' : ''} 
                                              {item.content[cardContent["heading"]]} 
                                              {cardContent["headingSuffix"] ?  ' ' + cardContent["headingSuffix"]: ''}
                                            </>
                                            )}
                                          </h5>
                                          )}
                                          {showArchiveBtn &&
                                            <button 
                                              className='btn btn-transparent btn-sm' 
                                              data-bs-toggle="tooltip" 
                                              data-bs-placement="top" 
                                              title="Archive" 
                                              id={item.content['_id']['$oid']}
                                              onClick={()=>{
                                                setTaskAsArchived(item.content['_id']['$oid'])
                                              }}
                                              style={{position: 'absolute',top: '5px',right: '0'}}
                                              >
                                              <span className='fas fa-times'></span>
                                            </button>
                                          }
                                        </div>
                                          {"text1" in cardContent && (
                                            <p className='text-600 mb-0'>
                                              {cardContent["text1Prefix"] ? cardContent["text1Prefix"] + ' ' : ''} 
                                              {item.content[cardContent["text1"]]}
                                              {cardContent["text1Suffix"] ?  ' ' + cardContent["text1Suffix"]: ''}
                                            </p>
                                          )}
                                          
                                          {"text2" in cardContent && (
                                            <p className='text-600 mb-1'>
                                              {cardContent["text2Prefix"] ? cardContent["text2Prefix"] + ' ' : ''} 
                                              {item.content[cardContent["text2"]]}
                                              {cardContent["text2Suffix"] ?  ' ' + cardContent["text2Suffix"]: ''}
                                            </p>
                                          )}
                                          
                                          {"pill1" in cardContent && item.content[cardContent["pill1"]] !== '' && (
                                            <span style={{ marginRight: 5, whiteSpace:'normal', textAlign:'left' }} className='badge badge-soft-warning fs--1 mb-1'>
                                              {cardContent["pill1Prefix"] ? cardContent["pill1Prefix"] + ' ' : ''} 
                                              {item.content[cardContent["pill1"]]}
                                              {cardContent["pill1Suffix"] ?  ' ' + cardContent["pill1Suffix"]: ''}
                                            </span>
                                          )}
                                          
                                          {"pill2" in cardContent && item.content[cardContent["pill2"]] !== '' && (
                                            <span style={{ marginRight: 5, whiteSpace:'normal', textAlign:'left' }} className='badge badge-soft-warning fs--1 mb-1'>
                                              {cardContent["pill2Prefix"] ? cardContent["pill2Prefix"] + ' ' : ''} 
                                              {item.content[cardContent["pill2"]]} 
                                              {cardContent["pill2Suffix"] ?  ' ' + cardContent["pill2Suffix"]: ''}
                                            </span>
                                          )}
                                        </div>
                                    }
                                    
                                  </div>
                                );
                              }}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
              </div>
            </div>
          );
        })}
      </DragDropContext>
    </div>
    </div>
    </div>
    
    </>
  );
}

export default Kanban;
