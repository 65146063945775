import React, { useState, useEffect } from "react";
import $ from 'jquery';



// Data to pass

// obj = {arr: Data array,
//     headers: headers,
//     link: solution_attached_app_path(@solution,@solution.attached_apps.find_by(name:'Task Details')),
//     docname:Your Docname,
//     doctype:Your Doctype,
//     solution_id: Your solution id,
//     company_id: your company id,
//     user_id: your user id,


// For reference
//     link: solution_attached_app_path(@solution,@solution.attached_apps.find_by(name:"Task Details")),
//     solution_id:@solution.id,
//     company_id:current_company.id,
//     user_id: current_user.id,

// Headers
// headers = [{label:'', key:'',type:''},{label:'', key:'',type:'',options: [{text: '', value: ''}, {text: '', value: ''}, {text: '', value: ''}]},]





const App = ({obj}) => {

  const {arr,headers,link,docname,doctype,solution_id,user_id,company_id} = obj
  const [data, setData] = useState(arr);
  const [searchTerm, setSearchTerm] = useState('');
  let visibleHeaders = headers.filter(h => h.type !== 'hidden');
  let hiddenHeaders = headers.filter(h => h.type === 'hidden');
  const newObj = {};
  visibleHeaders.forEach(obj => {
    newObj[obj.key] = obj.value;
  });
  const [newRow, setNewRow] = useState(newObj);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage,setPostPerPage] = useState(10)
  const [indexOfLastPost,setIndexOfLastPost] = useState(currentPage * postsPerPage);
  const [indexOfFirstPost,setIndexOfFirstPost] = useState(indexOfLastPost - postsPerPage);
  const [currentData,setCurrentData] = useState(data.slice(indexOfFirstPost, indexOfLastPost));
  const pageNumbers = [];
  
  for (let i = 1; i <= Math.ceil(data.length / postsPerPage); i++) {
    pageNumbers.push(i);
  }
  const paginate = pageNumber => setCurrentPage(pageNumber);
  
  const handleSearchInputChange = (e) => {
    setSearchTerm(e.currentTarget.value);
    filterData(e.currentTarget.value);
  };

  const filterData = (searchTerm) => {
    const filteredData = arr.filter(item => {
      return Object.values(item).some(value =>
        value?.toString()?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });

    setData(filteredData);
  };
  
  const handleAdd = () => {
    
    const newRowData = {...newRow,docname:docname,doctype:doctype,solution_id:solution_id,user_id:user_id,company_id:company_id}
    hiddenHeaders.forEach(header => {
      newRowData[header['key']] = header['value'];
    });
    let continueRequest = true
    if (obj.requiredFields && obj.requiredFields.length > 0) {
      const allFieldsPresent = obj.requiredFields.every(field => newRowData.hasOwnProperty(field) && newRowData[field] !== undefined && newRowData[field] !== null && newRowData[field] !== '');
      
      if (!allFieldsPresent) {
          continueRequest = false;
      }
    }
    if (continueRequest) {
      $('#loading').show()
      let req_url = '/api/v1/submit_data'
      let submit_obj = {obj: newRowData}
      if(obj.user_email){
        submit_obj['user'] = obj.user_email
      }
      if(obj.key){
        submit_obj['key'] = obj.key
      }
      let req_data = JSON.stringify(submit_obj)
      $.ajax({
        type: "POST",
        url: req_url,
        data: req_data,
        datatype : "application/json",
        contentType: "application/json; charset=utf-8",
        success: function(data){
          $('#loading').hide()
          if(obj.savePrompt){
            alert(obj.savePrompt)
          }else{
            alert('Data Saved successfully!')
          }
          window.location.reload(false);
        },
        error: function(err){
          $('#loading').hide()
          console.log(err);
          $(event.target).find('.alert-danger').show();
          alert('Something went wrong! Please try again.')
          setTimeout(function(){
          window.location.reload(false);
          },1000)
        }
      });
    } else {
      alert('Please fill all the required fields')
    }
  };

  const handleInputChange = (e, key) => {
    setNewRow({ ...newRow, [key]: e.target.value });
  };

  const setEdititing = (id) => {
    const newData = currentData.map((item) => {
      if (item['_id']['$oid'] === id) {
        return {
          ...item,
          isEditing: true,
        };
      } else {
        return item;
      }
    });
    setCurrentData(newData);
  }
  
  const cancelEdit = (idx) => {
    let tempData = [...currentData]
    let orgArr = arr.slice(indexOfFirstPost, indexOfLastPost)
    tempData[idx]= orgArr[idx]
    setCurrentData(tempData)
    // setData(arr)
  }
  
  const saveEdit=(id)=>{
    const editedData = currentData.filter((dt)=>{ if (dt['_id']['$oid'] == id) {
      delete dt.isEditing
      return dt
      } 
    })
    let continueRequest = true
    if (obj.requiredFields && obj.requiredFields.length > 0) {
      const allFieldsPresent = obj.requiredFields.every(field => editedData[0].hasOwnProperty(field) && editedData[0][field] !== undefined && editedData[0][field] !== null && editedData[0][field] !== '');
      
      if (!allFieldsPresent) {
        continueRequest = false;
      }
    }
    if (continueRequest) {
      $('#loading').show()
      let req_url = '/api/v1/update_data'
      
      let update_obj = {obj: editedData[0], id: id}
      if(obj.user_email){
        update_obj['user'] = obj.user_email
      }
      if(obj.key){
        update_obj['key'] = obj.key
      }
      
      let req_data = JSON.stringify(update_obj)
  
      $.ajax({
        type: "POST",
        url: req_url,
        data: req_data,
        datatype : "application/json",
        contentType: "application/json; charset=utf-8",
        success: function(data){
          $('#loading').hide()
          if(obj.updatePrompt){
            alert(obj.updatePrompt)
          }else{
            alert('Data updated successfully!')
          }
          window.location.reload(false);
        },
        error: function(err){
          $('#loading').hide()
          console.log(err);
          $(event.target).find('.alert-danger').show();
          alert('Something went wrong! Please try again.')
          setTimeout(function(){
            window.location.reload(false);
          },1000)
        }
      });
    } else {
      alert('Please fill all the required fields')
    }
  }

const handleDelete=(id)=>{
  let text = "Are you sure you want to delete this data?";
  if (confirm(text) == true){
    $('#loading').show()
    $.ajax({
      type: "POST",
      url: '/api/v1/delete_data',
      data: JSON.stringify({id: id}),
      datatype : "application/json",
      contentType: "application/json; charset=utf-8",
      success: function(data){
        $('#loading').hide()
        console.log(data)
        if(obj.deletePrompt){
          alert(obj.deletePrompt)
        }else{
          alert('Data deleted successfully!')
        }
        // record_user_action('Stage deleted','<%= current_user.first_name.titleize %>',"","",'<%= params[:oid] %>')
        window.location.reload(true);
        },
      error: function(err){
        $('#loading').hide()
        alert('Something went wrong! Please try again.')
        console.log(err)
      }
    })
  }
}
useEffect(() => {
  // Calculate the new values for indexOfLastPost, indexOfFirstPost, and currentData
  const newIndexOfLastPost = currentPage * postsPerPage;
  const newIndexOfFirstPost = newIndexOfLastPost - postsPerPage;
  const newCurrentData = data.slice(newIndexOfFirstPost, newIndexOfLastPost);
  
  // Update the state variables using the setter functions
  setIndexOfLastPost(newIndexOfLastPost);
  setIndexOfFirstPost(newIndexOfFirstPost);
  setCurrentData(newCurrentData);
}, [currentPage, postsPerPage, data]);


  return (<>
  
    <div className='table-responsive scrollbar text-sm' id={`editableTable-${docname}`}>
      <div className='d-flex align-items-center justify-content-end m-1 mb-2'>
        <div className='col-12 col-sm-3'>
          <label className='form-label'>Search</label>
          <input 
            type='text' 
            className='form-control' 
            value={searchTerm}
            onChange={handleSearchInputChange}
          />

        </div>
      </div>
      <table className="table table-striped table-hover text-center">
        <thead className="thead-dark">
          <tr className='text-white bg-secondary'>
           {visibleHeaders.map((header,i)=><th key={i} className='small'  style={{'minWidth':'200px'}}>{header.label}{obj.requiredFields && obj.requiredFields.includes(header.key) ? <span className=''>*</span>:null}</th>)}
          <th className='small'>Actions</th>
          </tr>
        </thead>
        <tbody>
   


        {currentData.map((row, rowIndex) => {
          let newLink = link ? link + "?oid=" + row["_id"]['$oid'] : '#'

          return (<tr key={rowIndex}>
        
            {visibleHeaders.map((header, headerIndex) => {
              return(
              <td key={headerIndex} className='small'>
       
                  {row.isEditing ? (
                    header.type === 'select' ? (
                      <select
                        className="form-select text-sm"
                        data-style="select-with-transition"
                        defaultValue={row[header.key]}
                        onChange={(e) =>
                          setCurrentData(
                            currentData.map((x) =>
                              x['_id']['$oid'] === row['_id']['$oid']
                                ? { ...x, [header.key]: e.target.value }
                                : x
                            )
                          )
                        }
                      >

                        <option value={row[header.key]} disabled>
                          {row[header.key]}
                        </option>
                        {header.options.map((option, i) => (
                          <option key={i} value={option.value}>
                            {option.text}
                          </option>
                        ))}
                      </select>
                    ) : header.type === 'textarea' ? (
                      <textarea
                        className="form-control text-sm"
                        value={row[header.key]}
                        disabled={header.type === 'disabled'}
                        onChange={(e) =>
                          setCurrentData(
                            currentData.map((x) =>
                              x['_id']['$oid'] === row['_id']['$oid']
                                ? { ...x, [header.key]: e.target.value }
                                : x
                            )
                          )
                        }
                      />
                    ) : (
                      <input
                        type={header.type}
                        className="form-control text-sm"
                        value={row[header.key]}
                        disabled={header.type === 'disabled'}
                        onChange={(e) =>
                          setCurrentData(
                            currentData.map((x) =>
                              x['_id']['$oid'] === row['_id']['$oid']
                                ? { ...x, [header.key]: e.target.value }
                                : x
                            )
                          )
                        }
                      />
                    )
                  ) : (
                    <a href={newLink} className="text-decoration-none text-dark" target={ newLink.includes('refresh') || newLink == '#' ? '_self' : '_blank'}>
                      {row[header.key] !== undefined ? row[header.key] : '-'}
                    </a>
                  )
                  }
              </td>)
            })}
            
            <td>
            {row.isEditing? 
              <div className='btn-group'>
                <button className="btn btn-sm btn-link mr-2" onClick={()=>saveEdit(row['_id']['$oid'])}><svg className="svg-inline--fa fa-check fa-w-16" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></button>
                <button className="btn btn-sm btn-link mr-2" onClick={()=>cancelEdit(rowIndex)}><svg className="svg-inline--fa fa-times fa-w-11" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" data-fa-i2svg=""><path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg></button>
              </div>    
                  :
              <span className='btn-group'>
                <button className="btn btn-link btn-sm" onClick={()=>setEdititing(row['_id']['$oid'])}><svg className="svg-inline--fa fa-pen fa-w-16 fs-0" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="pen" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z"></path></svg></button>
                <button className="btn btn-link btn-sm" onClick={()=>handleDelete(row['_id']['$oid'])}><svg className="svg-inline--fa fa-trash-alt fa-w-14" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"></path></svg></button>
              </span>
            }
            </td>
          </tr>
          )
        })}
  
          <tr>
            {visibleHeaders.map((header,i)=>
              <td key={i} className='small'>
                {header.type === 'select' || header.type === 'radio' || header.type === 'checkbox' ? (
                  <select className="form-select text-sm" id={header.key} value={newRow[header.key]} data-style="select-with-transition" onChange={(e) => handleInputChange(e, header.key)}>
                    <option value=''>Select</option>
                      {header.options.map((option, i) => (
                        <option key={i} value={option.value}>{option.text}</option>
                      ))}
                  </select>
                ) : header.type === 'disabled' ? (
                  <input className="form-control text-sm" type="text" id={header.key} value={newRow[header.key]} disabled onChange={(e) => handleInputChange(e, header.key)} />
                ) : header.type === 'textarea' ? (
                  <textarea className="form-control text-sm" id={header.key} value={newRow[header.key]} onChange={(e) => handleInputChange(e, header.key)} />
                ) : (
                  <input className="form-control text-sm" type={header.type} id={header.key} value={newRow[header.key]} onChange={(e) => handleInputChange(e, header.key)}/>
                )}
              </td>
            )}
            <td className="text-center">
              <button className="btn btn-primary btn-sm" onClick={()=>handleAdd()}>Add</button>
            </td>
          </tr>
        </tbody>
      </table>

      <div className="d-flex flex-column flex-md-row gap-2 align-items-center align-items-md-start justify-content-center justify-content-md-between  flex-wrap">
      <div className="text-center">
          <label className='d-flex align-items-center gap-2' >
            Show
            <select className="form-control custom-select" style={{'width':'60px'}} data-style="select-with-transition" onChange={(e)=>{
              setPostPerPage(e.currentTarget.value)
              setCurrentPage(1)
            }}>
              <option value={10}>10</option>
              <option vlaue={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
            Entries
          </label>
        </div>
        <div>
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-end">
              <li className={`page-item ${currentPage == 1 ? 'disabled' : 'cursor-pointer' }`} onClick={() => {
                if(currentPage > 1){
                  setCurrentPage(prev => prev - 1)
                }
              }}><p className="page-link border-0 border-end">Previous</p></li>
              {
                pageNumbers && pageNumbers.length <= 10 ? pageNumbers.map(number => {
                  return <li key={number} className="page-item" onClick={() => paginate(number)}><p className={`page-link border-top-0 border-bottom-0 cursor-pointer ${number == currentPage ? 'bg-primary text-white' : ''}`}>{number}</p></li>
                })
                :
                 currentPage == 1 || currentPage == 2 || currentPage == pageNumbers.length || currentPage == pageNumbers.length -1 || currentPage == pageNumbers.length -2 ? <>
                    <li className="page-item" onClick={() => paginate(1)}><p className={`page-link border-top-0 border-bottom-0 cursor-pointer ${1 == currentPage ? 'bg-primary text-white' : ''}`}>{1}</p></li>
                    <li className="page-item" onClick={() => paginate(2)}><p className={`page-link border-top-0 border-bottom-0 cursor-pointer ${2 == currentPage ? 'bg-primary text-white' : ''}`}>{2}</p></li>
                    <li className="page-item" onClick={() => paginate(3)}><p className={`page-link border-top-0 border-bottom-0 cursor-pointer ${3 == currentPage ? 'bg-primary text-white' : ''}`}>{3}</p></li>
                    <li className="page-item"><p className={`border-top-0 border-bottom-0 cursor-pointer`} style={{ padding: '.5rem .75rem', backgroundColor: 'white' }}>...</p></li>
                    <li className="page-item" onClick={() => paginate(pageNumbers.length-2)}><p className={`page-link border-top-0 border-bottom-0 cursor-pointer ${pageNumbers.length - 2 == currentPage ? 'bg-primary text-white' : ''}`}>{pageNumbers.length-2}</p></li>
                    <li className="page-item" onClick={() => paginate(pageNumbers.length-1)}><p className={`page-link border-top-0 border-bottom-0 cursor-pointer ${pageNumbers.length - 1 == currentPage ? 'bg-primary text-white' : ''}`}>{pageNumbers.length-1}</p></li>
                    <li className="page-item" onClick={() => paginate(pageNumbers.length)}><p className={`page-link border-top-0 border-bottom-0 cursor-pointer ${pageNumbers.length == currentPage ? 'bg-primary text-white' : ''}`}>{pageNumbers.length}</p></li>
                  </>
                  :
                  <>
                    <li className="page-item" onClick={() => paginate(currentPage - 1)}><p className={`page-link border-top-0 border-bottom-0 cursor-pointer`}>{currentPage -1}</p></li>
                    <li className="page-item"><p className={`page-link border-top-0 border-bottom-0 cursor-pointer bg-primary text-white`}>{currentPage}</p></li>
                    {
                      pageNumbers.length - 2 != currentPage +1 && <li className="page-item" onClick={() => paginate(currentPage + 1)}><p className={`page-link border-top-0 border-bottom-0 cursor-pointer`}>{currentPage +1}</p></li>
                    }
                    <li className="page-item"><p className={`border-top-0 border-bottom-0 cursor-pointer`} style={{ padding: '.5rem .75rem', backgroundColor: 'white' }}>...</p></li>
                    <li className="page-item" onClick={() => paginate(pageNumbers.length-2)}><p className={`page-link border-top-0 border-bottom-0 cursor-pointer ${pageNumbers.length - 2 == currentPage ? 'bg-primary text-white' : ''}`}>{pageNumbers.length-2}</p></li>
                    <li className="page-item" onClick={() => paginate(pageNumbers.length-1)}><p className={`page-link border-top-0 border-bottom-0 cursor-pointer ${pageNumbers.length - 1 == currentPage ? 'bg-primary text-white' : ''}`}>{pageNumbers.length-1}</p></li>
                    <li className="page-item" onClick={() => paginate(pageNumbers.length)}><p className={`page-link border-top-0 border-bottom-0 cursor-pointer ${pageNumbers.length == currentPage ? 'bg-primary text-white' : ''}`}>{pageNumbers.length}</p></li>
                  </>   
              }
              <li className={`page-item ${currentPage == pageNumbers.length ? 'disabled' : ' cursor-pointer' }`} onClick={() => {
                if(currentPage < pageNumbers.length){
                  setCurrentPage(prev => prev + 1)
                }
              }}><p className="page-link border-0 border-start">Next</p></li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    </>
  );
}

export default App;